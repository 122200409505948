<template>
  <div class>
    <div class="testimonal-card mx-auto">
      <div
        class="p-14 grid grid-cols-3 grid-rows-2 grid-flow-row overflow-hidden rounded-xl shadow-xl bg-white hover:shadow-2xl transition-shadow duration-300 ease-in-out"
      >
        <div class="col-span-3 row-span-4">
          <div class="card-title flex items-center justify-between has-font-poppins-bold">
            <div class="flex items-center" :class="iconName? 'w-3/5  h-24 ' : 'w-full'">
              <div data-aos="fade-up" data-aos-delay="300" :class="iconName ? 'mb-3' : ''">{{title}}</div>
            </div>
            <div
              data-aos="zoom-in-right"
              data-aos-delay="500"
              class="w-2/5 card-icon px-1"
              v-if="iconName"
            >
              <!-- <img :src="require('../../assets/images/' + iconName + '')" alt="Placeholder" class="object-cover h-26 " /> -->
              <image-item
                class="article-item__image"
                :source="require('../../assets/images/' + iconName + '')"
              />
            </div>
          </div>
          <hr class="my-0 card-section-top" data-aos="fade-right" data-aos-delay="350" />
        </div>

        <div class="col-span-3 row-span-1 my-1">
          <div class="flex align-bottom flex-col p-2 md:p-4">
            <div class="card-text">
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="mt-2 pb-5 has-font-circular-book"
                :class="iconName ? 'h-52 md:h-40' : 'h-40'"
              >{{description}}</p>
            </div>
            <div v-if="showTapLink">
              <router-link
                data-aos="fade-up"
                data-aos-delay="400"
                class="has-font-circular-book card-text text-light-gray"
                to="/contact-us"
              >Tap here to contact now</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "../ImageItem.vue";
export default {
  components: { ImageItem },
  data() {
    return {};
  },
  props: {
    title: String,
    description: String,
    textHeight: String,
    iconName: String,
    showTapLink: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";
.testimonal-card {
  // width: 22rem;

  @media (max-width: 770px) {
    // width: 18rem;
  }
}
.card-section-top {
  border-top: 1px solid #707070 !important;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  @media (max-width: 1030px) {
    font-size: 1.2em;
  }
}
.card-icon {
  height: 50px;
  width: 60px;
  img {
    height: 100%;
    // width: 100%;
    object-fit: contain;
    margin-left: auto;
  }
}

.card-text {
  font-size: 1em;
  color: $gray-dark;
  // font-family: $font-segoeUI;
  line-height: 1.5;
}
</style>