<template>
  <figure v-lazyload class="image__wrapper">
    <ImageSpinner class="image__spinner" />
    <img class="image__item" :data-url="source" alt="random image" :class="imageClass" />
  </figure>
</template>

<script>
import ImageSpinner from "./ImageSpinner";

export default {
  name: "ImageItem",
  components: {
    ImageSpinner
  },
  props: {
    imageClass: String,
    source: {
      type: String,
      required: true
    },
    flexCenter: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/Variables.scss";

figure {
  width: 100%;
  height: 100%;
}
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
