<template>
  <div class="services-page">
    <window-height>
      <div class="container flex items-center">
        <slide-one
          :slideOneTitleOne="'Our Services.'"
          :SlideOneDescription="'We gain satisfaction by making our clients and their customers happy. Delivering future ready software solutions and solving the toughest software challenge is what we do.'"
          :slideOneSideImage="'services-side.gif'"
          :titleOnly="false"
        />
      </div>
    </window-height>
    <container-section>
      <div class="our-products mt-2">
        <div class="slide-title font-black pt-5 pb-2 lg:w-4/5">Services</div>

        <div class="row process-content flex flex-wrap justify-center pt-20">
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            class="col-sm-12 col-md-6 mb-4"
            v-for="item in Expertisedata"
            :key="item.id"
          >
            <card-component
              :title="item.title"
              :description="item.text"
              :textHeight="'h-48 md:h-40'"
              :iconName="item.icon"
              :showTapLink="true"
            />
          </div>
        </div>
      </div>

      <div class="trusted-customers mt-5 sm:py-20">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black py-5"
        >Trusted by customers</div>
        <div class="row mt-4">
          <div
            data-aos="zoom-in-right"
            data-aos-delay="300"
            class="col-6 col-md-3 flex flex-wrap justify-center"
            v-for="image in PartnersData"
            :key="image.id"
          >
            <image-rounded
              :partnerName="image.image"
              :image-class="image.id == '2' || image.id == '17' ? 'p-4' : ''"
            />
          </div>
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :sectionHeight="false">
      <div class="container ourServices mt-5 py-16 sm:pt-16">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black"
        >Are you looking for services</div>
        <div data-aos="fade-up" data-aos-delay="350" class="slide-title font-black pb-5">like ours?</div>
        <base-button
          data-aos="fade-right"
          data-aos-delay="300"
          :href="'/contact-us'"
          id="GetInTouchoneAbout"
          class="btn-primary-dark"
          :isBorderRadiud="false"
        >Get in Touch</base-button>
      </div>
      <div class="about-footer relative">
        <Footer />
      </div>
    </container-section>
  </div>
</template>

<script>
import CardComponent from "../components/AboutUs/CardComponent.vue";
import BaseButton from "../components/BaseButton.vue";
import ContainerSection from "../components/ContainerSection.vue";
import SlideOne from "../components/Home/SlideOne.vue";
import DataServies from "../services/DataService";
import Footer from "../components/Home/Footer";
import ImageRounded from "../components/ImageRounded.vue";
import WindowHeight from "../components/WindowHeight.vue";

export default {
  title: "Magure - Services",
  components: {
    ContainerSection,
    SlideOne,
    CardComponent,
    BaseButton,
    Footer,
    ImageRounded,
    WindowHeight
  },

  data() {
    return {
      Expertisedata: DataServies.expertiseProducts,
      PartnersData: DataServies.partners,
      windowHeight: window.innerHeight
    };
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  }
};
</script>

<style>
</style>