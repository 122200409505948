<template>
  <div
    class="image-rounded p-2.5 mb-5 hover:shadow-lg transition-shadow duration-300 ease-in-out flex items-center"
  >
    <!-- <img :src="require('../assets/images/partners/' + partnerName + '' )" alt=""> -->
    <image-item
      class="article-item__image"
      v-if="partnerName"
      :source="require('../assets/images/partners/' + partnerName + '')"
      :image-class="imageClass"
    />
  </div>
</template>

<script>
import ImageItem from "./ImageItem.vue";
export default {
  components: { ImageItem },
  props: {
    partnerName: String,
    imageClass: String
  }
};
</script>

<style lang="scss" scoped>
.image-rounded {
  width: 150px;
  height: 150px;
  // border:  1px solid #707070;
  border-radius: 50%;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.article-item__image {
  margin: 0;
  padding: 0;
}
</style>