<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-7">
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        class="slide-title-large text-dark"
      >{{slideOneTitleOne}}</div>
      <div
        data-aos="fade-up"
        data-aos-delay="350"
        class="slide-title-large text-dark"
      >{{slideOneTitleTwo}}</div>
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        class="slide-title-large text-dark"
      >{{slideOneTitleThree}}</div>

      <div
        data-aos="fade-up"
        data-aos-delay="450"
        class="slide-description text-light-gray w-5/6 py-10 has-font-circular-book"
        v-if="SlideOneDescription && !titleOnly"
      >{{SlideOneDescription}}</div>
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        class="slide-left-button text-left pt-5 pb-2 flex items-center gap-4 flex-wrap"
        v-if="!titleOnly"
      >
        <base-button
          id="ChatWithDocs"
          class="btn-primary-dark"
          style="height: 70px;"
          v-bind:state="buttonState"
          aria-expanded="false"
          @click="redirectToChatWithDocs"
          >Chat with Docs</base-button>

          <base-button
          :href="'/face-compare'"
          id="OtherAIProduct"
          class="btn-primary px-0"
          v-bind:state="buttonState"
          aria-expanded="false"
        >Other AI product</base-button>
      </div>
      <div class="flex mt-3" v-if="!titleOnly">
        <social-icon
          data-aos="zoom-in-up"
          data-aos-delay="650"
          v-for="icon of SocialIconData"
          :key="icon.title"
          :iconName="icon.image"
          :path="icon.url"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-5 text-center d-none d-lg-block d-xl-block">
      <div class="title-image ml-auto flex content-end">
        <!-- <img src="../../assets/images/slide-one-title-img.svg" alt=""> -->
        <!-- <img class="object-contain" :src="require('../../assets/images/' + slideOneSideImage + '')" alt=""> -->
        <image-item
          data-aos="zoom-in-right"
          data-aos-delay="850"
          class="article-item__image"
          v-if="slideOneSideImage"
          :source="require('../../assets/images/' + slideOneSideImage + '')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
import ButtonState from "../../lib/button-state";
import SocialIcon from "../../icons/SocialIcon.vue";
import Dataservice from "../../services/DataService";
import ImageItem from "../ImageItem.vue";

export default {
  components: {
    BaseButton,
    SocialIcon,
    ImageItem
  },
  data() {
    return {
      buttonState: ButtonState.Ready,
      SocialIconData: Dataservice.socialIcons
    };
  },

  methods: {
    redirectToChatWithDocs() {
      window.open('https://chatwithdocs.maguresoftwares.com/', '_blank')
    }
  },

  mounted() {},

  props: {
    slideOneTitleOne: String,
    slideOneTitleTwo: String,
    slideOneTitleThree: String,
    SlideOneDescription: String,
    slideOneSideImage: String,
    slideOneButtonTxt: String,
    titleOnly: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/Home/SlideOne.scss";
</style>
