<template>
   <div class="landing-background" :class="containerClass" :style="[{height:sectionHeight ? '550px' : 'auto'},{backgroundColor:backgroundColor ? backgroundColor : 'none'}]">
       <div v-if="imageName">
           <div class="background-gradient"></div>
            <img :src="require('../assets/images/' + imageName + '')" alt="" class="layer" >
       </div>

       <div class="layer" v-else :style="backgroundColor ? backgroundColor : '#FFFFFF' ">
           
       </div>
       <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        backgroundColor: {
            type: String
        },
        containerClass: {
            default: "container",
            type: String
        },
        imageName: {
            type: String
        },
        sectionHeight: Boolean
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/components/ContainerSection.scss";

</style>