<template>
  <div class="window-height" data-aos-container="#scroll-container">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.window-height {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
</style>